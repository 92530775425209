.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  position: relative;
}

.center-button {
  position: absolute;
  width: 100px;
  height: 100px;
  background-size: cover;
  border-radius: 5%;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.center-link {
  position: absolute;
}

.button-wrapper {
  position: absolute;
}

@media screen and (max-width: 768px) {
  .landing-page {
    flex-wrap: wrap;
  }
} ;
